import classNames from 'classnames';
import * as React from 'react';
import styles from './Heading.module.scss';

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingStyle = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface HeadingProps {
	children: React.ReactNode;
	className?: string;
	id?: string;
	headingLevel: HeadingLevel;
	style?: HeadingStyle;
	margined?: boolean;
}

export const Heading: React.FC<HeadingProps> = ({ children, margined, className, id, headingLevel, style }) => {
	return children
		? React.createElement(
				headingLevel,
				{ className: classNames(styles.Heading, styles[`Heading___${style}`], margined ? styles.Heading___margined : '', className), id: id },
				<span className={styles.Heading_wrapper}>{children}</span>,
		  )
		: null;
};
