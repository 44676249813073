import config from 'utilities/imageSizes';

interface GetImageUrlProps {
	url: string;
	focalPoint?: {
		left: number;
		top: number;
	};
	width?: number;
	height?: number;
	format?: 'webp';
}

export const GetImageUrl = ({ url, focalPoint, width, height = 0, format }: GetImageUrlProps): string => {
	if (!url) return '';
	if (url?.endsWith('.svg')) return url;
	let imageUrl = `${url}?width=${width}`;

	if (height > 0) {
		imageUrl = `${imageUrl}&height=${height}&mode=crop`;
	}
	if (height > 0 && focalPoint) {
		imageUrl = `${imageUrl}&rxy=${focalPoint.left.toString().replace(',', '.')},${focalPoint.top.toString().replace(',', '.')}`;
	}

	if (format) imageUrl = `${imageUrl}&format=webp`;
	return imageUrl;
};

export const GetSrcSetString = ({
	url,
	focalPoint,
	format,
	aspectRatio,
	height = 0,
	overwriteWidth,
}: {
	url: string;
	focalPoint: { left: number; top: number };
	format?: 'webp';
	aspectRatio?: number;
	height: number;
	overwriteWidth?: number;
}): string => {
	const { imageSizes } = config.images;

	let string = '';

	if (overwriteWidth !== null && overwriteWidth !== undefined) {
		return GetImageUrl({
			url,
			focalPoint,
			width: overwriteWidth,
			height: height,
			format,
		});
	}

	imageSizes.map((width) => {
		let convertedHeight = height;
		if (aspectRatio) {
			convertedHeight = aspectRatio * width;
		}

		return (string += `${GetImageUrl({
			url,
			focalPoint,
			width,
			height: convertedHeight,
			format,
		})} ${width}w, `);
	});

	return string.substring(0, string.length - 2);
};
