import ArrowDown from './arrow-down.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import ArrowUp from './arrow-up.svg';
import Close from './close.svg';
import Delete from './delete.svg';
import Download from './download2.svg';
import Edit from './edit.svg';
import Maalflag from './maalflag.svg';
import OK from './ok.svg';
import Plus from './plus.svg';
import Print from './print.svg';
import Reset from './reset.svg';
import Save from './save.svg';
import Zero from './zero.svg';

const Icons = {
	ArrowDown,
	ArrowLeft,
	ArrowRight,
	ArrowUp,
	Close,
	Delete,
	Download,
	Edit,
	Maalflag,
	OK,
	Plus,
	Print,
	Reset,
	Save,
	Zero,
};
export default Icons;
export type IconName = keyof typeof Icons;
