import classNames from 'classnames';
import * as React from 'react';
import styles from './Button.module.scss';

export interface ButtonProps extends Omit<React.ComponentProps<'button'>, 'className' & 'type'> {
	className?: string;
	onClick?: React.MouseEventHandler;
	children: React.ReactNode;
	type?: UI.ButtonType;
	buttonStyle?: 'primary-dark' | 'secondary-dark' | 'primary-light' | 'secondary-light' | 'primary-theme' | 'secondary-theme';
	buttonSize?: 'small' | 'medium' | 'big';
	buttonFontWeight?: 'normal';
	ariaLabel?: string;
	hidden?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
	className,
	onClick,
	children,
	type = 'button',
	buttonStyle = 'primary-dark',
	buttonSize = 'big',
	buttonFontWeight,
	ariaLabel,
	...buttonProps
}: ButtonProps) => (
	<button
		className={classNames(
			styles.Button,
			styles[`Button___${buttonStyle}`],
			styles[`Button___${buttonSize}`],
			styles[`Button___fontWeight${buttonFontWeight}`],
			className,
		)}
		onClick={onClick}
		// eslint-disable-next-line react/button-has-type
		type={type}
		aria-label={ariaLabel}
		{...buttonProps}
	>
		{children}
	</button>
);
