export interface UploadButtonProps {
	id: string;
	label: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({ id, label }: UploadButtonProps) => (
	<>
		<input type="file" id={id} className="upload-file" />

		<label htmlFor={id}>
			<span className="alt-btn alt-btn-upload mr-2">{label}</span>
		</label>
	</>
);

export { UploadButton };
