import classNames from 'classnames';
import DownloadIcon from 'icons/download.svg';
import styles from './DownloadButton.module.scss';

export interface DownloadButtonProps {
	downloadUrl: string;
	label: string;
	onClick?: () => void;
	displayComponent?: React.ReactNode;
}

export const DownloadButton = ({ downloadUrl, label, onClick, displayComponent }: DownloadButtonProps) => {
	return (
		<div className={styles.DownloadButton}>
			{displayComponent}
			<a
				className={classNames(styles.DownloadButton_button, displayComponent ? styles.DownloadButton_button___spacing : undefined)}
				href={downloadUrl}
				download
				onClick={onClick && onClick}
			>
				<DownloadIcon /> <span className={styles.DownloadButton_buttonText}>{label} </span>
			</a>
		</div>
	);
};
