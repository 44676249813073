interface sanitizeAspectRatioProps {
	aspectRatio?: '16:9' | '1:1' | '9:16';
	defaultRatio?: '16:9' | '1:1' | '9:16';
}

export const sanitizeAspectRatio = ({ aspectRatio, defaultRatio }: sanitizeAspectRatioProps) => {
	if (aspectRatio) {
		return aspectRatio.replace(':', '-');
	}
	if (defaultRatio) {
		return defaultRatio.replace(':', '-');
	}
	return '16-9';
};
