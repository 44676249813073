import classNames from 'classnames';
import { Button } from 'components/1-atoms/Buttons';
import { Heading } from 'components/1-atoms/Heading';
import { HelpButton } from 'components/1-atoms/HelpButton';
import { Icon } from 'components/1-atoms/Media';
import CheckmarkGreen from 'icons/checkmark-green.svg';
import CloseIcon from 'icons/icon_close_cross.svg';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import focusTrap from 'utilities/focusTrap';
import styles from './Modal.module.scss';

export interface ModalProps {
	header?: string;
	headerStyle?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	show?: boolean;
	noCloseButton?: boolean;
	onClose: () => void;
	ariaLabel?: string;
	ariaLabelClose?: string;
	children?: React.ReactNode;
	id?: string;
	size?: 'small' | 'large';
	withIcon?: 'checkmark' | 'exclamationmark';
	style?: 'search';
}

export const Modal: React.FC<ModalProps> = ({
	show = false,
	header,
	headerStyle = 'md',
	noCloseButton = false,
	onClose,
	ariaLabel = undefined,
	ariaLabelClose = 'Luk',
	children,
	id,
	size = 'small',
	withIcon,
	style,
}: React.PropsWithChildren<ModalProps>) => {
	const modal = useRef<HTMLDivElement>(null);
	const backdrop = useRef<HTMLDivElement>(null);
	useEffect(() => modal.current?.focus(), [show]);
	useEffect(() => {
		if (!modal.current) return;
		focusTrap(modal.current);
	}, []);
	const transitionModal = useRef(null);
	const transitionBackdrop = useRef(null);
	return (
		<>
			<Transition
				in={show}
				timeout={300}
				nodeRef={transitionModal}
				onEnter={() => {
					modal.current?.classList.add(styles.Modal___visible);
					modal.current?.classList.remove(styles.Modal___hidden);
				}}
				onEntering={() => {
					modal.current?.classList.add(styles.Modal___show);
				}}
				onExit={() => {
					modal.current?.classList.remove(styles.Modal___show);
				}}
				onExited={() => {
					modal.current?.classList.remove(styles.Modal___visible);
					modal.current?.classList.add(styles.Modal___hidden);
				}}
			>
				<div
					className={classNames(styles.Modal, styles.Modal___fade)}
					tabIndex={-1}
					aria-label={ariaLabel}
					aria-hidden={!show}
					aria-modal="true"
					role="dialog"
					onClick={(e) => e.target === modal.current && onClose()}
					onKeyUp={(e) => e.code === 'Escape' && onClose()}
					ref={modal}
					data-id={id}
				>
					<div className={classNames(styles.Modal_dialog, styles[`Modal_dialog___${size}`])} role="document">
						<div className={classNames(styles.Modal_content, withIcon && styles.Modal_content___withIcon)}>
							{withIcon === 'checkmark' && (
								<Icon className={styles.Modal_helpButton}>
									<CheckmarkGreen />
								</Icon>
							)}
							{withIcon === 'exclamationmark' && (
								<HelpButton className={styles.Modal_helpButton} size="big" notClickable label="!" style="dark" />
							)}

							<div className={styles.Modal_contentContainer}>
								<div className={styles.Modal_header}>
									{header && (
										<Heading className={styles.Modal_headerHeading} headingLevel="h2" style={headerStyle}>
											{header}
										</Heading>
									)}
									{!noCloseButton && (
										<Button
											ariaLabel={ariaLabelClose}
											className={styles.Modal_headerClose}
											buttonSize="small"
											onClick={() => onClose()}
											data-close={id} // Do not delete: Required for Cludo
										>
											<CloseIcon />
										</Button>
									)}
								</div>
								<div className={classNames(styles.Modal_body, style && styles[`Modal_body___${style}`])}>{children}</div>
							</div>
						</div>
					</div>
				</div>
			</Transition>
			<Transition
				in={show}
				timeout={300}
				nodeRef={transitionBackdrop}
				onEnter={() => {
					backdrop.current?.classList.remove(styles.Modal_backdrop___hidden);
				}}
				onEntering={() => {
					backdrop.current?.classList.add(styles.Modal_backdrop___show);
				}}
				onExit={() => {
					backdrop.current?.classList.remove(styles.Modal_backdrop___show);
				}}
				onExited={() => {
					backdrop.current?.classList.add(styles.Modal_backdrop___hidden);
				}}
			>
				<div ref={backdrop} className={classNames(styles.Modal_backdrop, styles.Modal_backdrop___fade, styles.Modal_backdrop___hidden)} />
			</Transition>
		</>
	);
};
