import classNames from 'classnames';
import { DOPIcon } from 'components/1-atoms/Media';
import { IconName } from '../../../../icons';
import styles from './AlternativeButton.module.scss';

export interface AlternativeButtonProps extends Omit<React.ComponentProps<'button'>, 'className' & 'type'> {
	label: string;
	hideLabel?: boolean;
	icon?: IconName;
	className?: string;
	type?: UI.ButtonType;
	flipped?: boolean;
	darkMode?: boolean;
}

export const AlternativeButton: React.FC<AlternativeButtonProps> = ({
	label,
	hideLabel = false,
	icon,
	className,
	type,
	flipped = false,
	darkMode = false,
	...buttonProps
}: AlternativeButtonProps) => {
	return (
		<button
			className={classNames(styles.AlternativeButton, { [styles.AlternativeButton___flipped]: flipped }, className)}
			type={type}
			aria-label={hideLabel ? label : null}
			{...buttonProps}
		>
			<>
				<span className={classNames(styles.AlternativeButton_icon, { [styles.AlternativeButton_icon___darkMode]: darkMode })}>
					<DOPIcon name={icon as IconName} size="sm" />
				</span>
				{!hideLabel ? (
					<span
						className={classNames(styles.AlternativeButton_text, {
							[styles.AlternativeButton_text___darkMode]: darkMode,
							[styles.AlternativeButton_text___flipped]: flipped,
						})}
					>
						{label}
					</span>
				) : null}
			</>
		</button>
	);
};
