import classNames from 'classnames';
import { sanitizeAspectRatio } from 'utilities/sanitizeAspectRatio';
import styles from './Video.module.scss';

export interface VideoProps {
	className?: string;
	url: string;
	title?: string;
	aspectRatio?: '16:9' | '1:1' | '9:16';
	teaser?: boolean;
	embedId: string;
}

export const Video: React.FC<VideoProps> = ({ url, title = 'Videotool player', aspectRatio, className, embedId }) => {
	const aspectRatioSanitized = sanitizeAspectRatio({ aspectRatio, defaultRatio: '16:9' });
	return (
		<div className={classNames(styles.Video, styles[`Video___${aspectRatioSanitized}`], className)}>
			{embedId ? (
				<div className={styles.Video_embed} id={embedId}></div>
			) : (
				<iframe className={styles.Video_Frame} title={title} src={url} allowFullScreen allow="autoplay; fullscreen" loading="lazy"></iframe>
			)}
		</div>
	);
};
