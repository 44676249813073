import { getKeyboardFocusableElements } from './getKeyboardFocusableElements';

function focusLast(event: KeyboardEvent, lastItem: HTMLElement): void {
	if (event.code === 'Tab' && event.shiftKey) {
		event.preventDefault();
		lastItem.focus();
	}
}

function focusFirst(event: KeyboardEvent, firstItem: HTMLElement): void {
	if (event.code === 'Tab' && !event.shiftKey) {
		event.preventDefault();
		firstItem.focus();
	}
}

export default function focusTrap(element: HTMLElement, focusFirstElm?: boolean): void {
	const focusables = getKeyboardFocusableElements(element);
	const first = focusables[0];
	const last = focusables[focusables.length - 1];
	focusFirstElm && first.focus();
	first.addEventListener('keydown', (event) => {
		focusLast(event, last);
	});
	last.addEventListener('keydown', (event) => {
		focusFirst(event, first);
	});
}

export function removeFocusTrap(element: HTMLElement): void {
	const focusables = getKeyboardFocusableElements(element);
	const first = focusables[0];
	const last = focusables[focusables.length - 1];
	first.removeEventListener('keydown', (event) => {
		focusLast(event, last);
	});
	last.removeEventListener('keydown', (event) => {
		focusFirst(event, first);
	});
}
