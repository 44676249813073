import classNames from 'classnames';
import * as React from 'react';
import styles from './HelpButton.module.scss';
export interface HelpButtonProps {
	className?: string;
	label?: string;
	describedById?: string;
	helpText?: string;
	ariaLabel?: string;
	onClick?: React.MouseEventHandler;
	style?: 'dark';
	margined?: boolean;
	size?: 'big';
	notClickable?: boolean;
	noMargin?: boolean;
}

export const HelpButton: React.FC<HelpButtonProps> = ({
	className,
	label = '?',
	describedById,
	onClick,
	ariaLabel,
	helpText,
	style,
	margined,
	noMargin,
}: HelpButtonProps) => {
	return (
		<button
			className={classNames(
				styles.HelpButton,
				styles[`HelpButton___${style}`],
				margined && styles.HelpButton___margined,
				noMargin && styles.HelpButton___noMargin,
				className,
			)}
			type="button"
			onClick={onClick}
			aria-label={ariaLabel}
		>
			<span aria-hidden={true}>{label}</span>
			{helpText && (
				<span className="sr-only" id={describedById}>
					{helpText}
				</span>
			)}
		</button>
	);
};
