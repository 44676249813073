import classNames from 'classnames';
import styles from './Icon.module.scss';
// TODO kodes om til at bruge skats ikon fil: https://skat.dk/ds/ikoner.html evt. laves om til svg??

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'auto';
export interface IconProps {
	title?: string;
	className?: string;
	children: React.ReactNode;
	ariaHidden?: boolean;
	style?: 'circle';
	size?: IconSize;
}

export const Icon: React.FC<IconProps> = ({ title, style, size = 'md', className, ariaHidden = true, children }) => (
	<span
		aria-label={title}
		className={classNames(styles.Icon, styles[`Icon___${style}`], styles[`Icon___${size}`], className)}
		aria-hidden={ariaHidden}
	>
		{children}
	</span>
);
