export const imageHeightConverter = (height: UI.ImageHeight) => {
	if (!height) return 0;
	switch (height) {
		case 'XSmall':
			return 150;
		case 'Small':
			return 300;
		case 'Medium':
			return 400;
		case 'Large':
			return 600;
		default:
			return 0;
	}
};
