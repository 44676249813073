import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { GetImageUrl, GetSrcSetString } from 'utilities/getImageUrl';
import { imageHeightConverter } from 'utilities/imageHeightConverter';
import config from 'utilities/imageSizes';
import { inView } from 'utilities/inView';
import styles from './Picture.module.scss';

export interface PictureProps {
	altText: string;
	assetClassName?: string;
	aspectRatio?: number;
	border?: boolean;
	className?: string;
	focalPoint?: { left: number; top: number };
	height?: UI.ImageHeight;
	isCover?: boolean;
	isFullHeight?: boolean;
	isPositionedAbsolute?: boolean;
	lazyload?: boolean;
	objectFitFallback?: boolean;
	sizes?: string;
	url: string;
	padding?: 'none' | 'small' | 'large';
	disableOptimization?: boolean;
}

export const Picture: React.FC<PictureProps> = ({
	altText,
	assetClassName,
	aspectRatio,
	border = false,
	className,
	focalPoint = { left: 0.5, top: 0.5 },
	height,
	isCover = true,
	isFullHeight,
	isPositionedAbsolute = false,
	lazyload = true,
	objectFitFallback = true,
	sizes = '100vw',
	url,
	padding = 'none',
	disableOptimization = false,
}) => {
	const { thumbnailSize, fallbackSize } = config.images;
	const thumbnailImageUrl = GetImageUrl({ url, width: thumbnailSize });
	const thumbnailImageUrlWebp = GetImageUrl({ url, width: thumbnailSize, format: 'webp' });
	const [src, setSrc] = useState(thumbnailImageUrl);
	const [imgSrcSet, setImgSrcSet] = useState(thumbnailImageUrl);
	const [sourceSrcSet, setSourceSrcSet] = useState(thumbnailImageUrlWebp);
	const [isFallback, setIsFallback] = useState(false);

	const pictureRef = useRef(null);
	useEffect(() => {
		if (objectFitFallback) objectFit();
		lazyload === true ? inView({ elm: pictureRef.current, callback: reveal }) : reveal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	const reveal = () => {
		const srcSetString = GetSrcSetString({
			url,
			focalPoint,
			aspectRatio,
			height: imageHeightConverter(height),
			overwriteWidth: !isCover && height ? 0 : null,
		});
		const srcSetStringWebp = GetSrcSetString({
			url,
			focalPoint,
			format: 'webp',
			aspectRatio,
			height: imageHeightConverter(height),
			overwriteWidth: !isCover && height ? 0 : null,
		});
		const srcString = GetImageUrl({
			url,
			width: height && !isCover ? 0 : fallbackSize,
			height: height ? imageHeightConverter(height) : isCover ? fallbackSize * aspectRatio : 0,
		});

		setImgSrcSet(srcSetString);
		setSourceSrcSet(srcSetStringWebp);
		setSrc(srcString);
	};

	const objectFit = () => {
		// If the useragent doesn't support ObjectFit (IE), we need to
		// add fallback image to the picture element
		const supportObjectFit = 'objectFit' in document.documentElement.style;

		if (!supportObjectFit) setIsFallback(true);
	};

	return (
		<picture
			ref={pictureRef}
			className={classNames(
				styles.Picture,
				{
					[styles.Picture___cover]: isCover,
					[styles.Picture___positionAbsolute]: isPositionedAbsolute,
					[styles.Picture___isFallback]: isFallback,
					[styles.Picture___isFullHeight]: isFullHeight,
					[styles[`Picture___${height}`]]: !isFullHeight && height,
					[styles.Picture___border]: border,
				},
				styles[`Picture___padding${padding}`],
				className,
			)}
			style={isFallback ? { backgroundImage: `url(${src})` } : {}}
		>
			{!isFallback && (
				<>
					{!src?.endsWith('.svg') && !url?.endsWith('.gif') && disableOptimization === false && (
						<source sizes={sizes} type="image/webp" srcSet={sourceSrcSet} />
					)}
					<img
						className={classNames(styles.Picture_asset, assetClassName)}
						src={disableOptimization ? url : src}
						alt={altText}
						srcSet={!src?.endsWith('.svg') ? (disableOptimization === false ? imgSrcSet : null) : null}
						sizes={disableOptimization === false ? sizes : null}
					/>
				</>
			)}
		</picture>
	);
};
