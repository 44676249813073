import Icons, { IconName } from '../../../../icons';

export interface DOPIconProps {
	name: IconName;
	ariaLabel?: string;
	className?: string;
	size?: DOPIconSize;
}

const sizes = {
	sm: '16',
	md: '32',
	lg: '48',
	xl: '64',
};

export type DOPIconSize = keyof typeof sizes;

export const DOPIcon: React.FC<DOPIconProps> = ({ name, size = 'md', className, ariaLabel }) => {
	const stringSize = sizes[size];
	return Icons[name]({
		width: stringSize,
		height: stringSize,
		viewBox: '0 0 32 32',
		className,
		'aria-label': ariaLabel ? ariaLabel : null,
		'aria-hidden': !ariaLabel ? true : null,
	});
};
